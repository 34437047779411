/* globals require module */
const Queue =  require( "./queue" ).default;

module.exports = (() => {
	if ( "KonnektObject" in globalThis ) {
		const konnekt = globalThis[ globalThis.KonnektObject ];

		if ( typeof konnekt === "function" && Array.isArray( konnekt.q )) {
			Queue.supplant( konnekt ).flush();
			return konnekt;
		}
	}

	const konnekt = ( ...args ) => { konnekt.q.push( args ) };
	konnekt.q = [];
	konnekt.l = 1 * new Date();

	Queue.supplant( konnekt ).flush();
	return konnekt;
})();
