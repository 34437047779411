import Konnektor from "./konnektor";
import { withErrorCallback } from "./utils";

class Queue {
	constructor( commands ) {
		this.__flushing  = false;
		this.__konnektor = new Konnektor();
		this.__queue     = Array.isArray( commands ) ? commands : [];
		this.__public    = {
			push : ( ...commands ) => {
				this.push( ...commands );
				this.flush();
			}
		};
	}

	static supplant( obj ) {
		const queue = new Queue( obj.q );
		obj.q = queue.__public;

		return queue;
	}

	push( ...commands ) {
		this.__queue.push( ...commands );
	}

	flush() {
		if ( this.__flushing ) return;
		this.__flushing = true;

		const queue = this.__queue;
		const konnektor = this.__konnektor;

		// The double loop construct avoids having to check queue.length during
		// iteration over individual items. Only after a single 'pass' over the
		// queue does it check if the queue length grew, which would mean there
		// were callbacks on the queue through which additional commands were
		// added onto the queue as it was being flushed.
		for ( let n = 0; n !== queue.length; ) {
			let i = n;
			n = queue.length;
			for ( ; i !== n; ++i ) {
				let command = queue[ i ];

				if ( command.length === 0 ) continue;

				let args;
				[ command, ...args ] = command;

				switch ( typeof command ) {
					default : break;
					case "function" :
						command = withErrorCallback( command );
						command.call( globalThis, logError, this.__public );
						break;
					case "string" :
						if ( Konnektor.has( command )) {
							command = withErrorCallback( konnektor[ command ]);
							command.call( konnektor, logError, ...args );
						}
						break;
				}
			}
		}

		// Prune the queue once fully processed.
		queue.length = 0;
		this.__flushing = false;
	}
}

function logError( error ) {
	if ( "console" in globalThis ) {
		globalThis.console.logError( error );
	}
}


export { Queue as default };
