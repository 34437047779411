import commands from "./commands";

const lookup = new Set();

class Konnektor {
	static has( command ) {
		return lookup.has( command );
	}
}

Object
	.keys( commands )
	.forEach( key => {
		lookup.add( key );
		Konnektor.prototype[ key ] = commands[ key ];
	});

export { Konnektor as default };
