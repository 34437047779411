/* global require */
const context = require.context( ".", true, /(?!index)\.js$/, "sync" );

const commands = {};
context
	.keys()
	.forEach( key => {
		const exported = context( key );
		if ( !exported.name ) return;
		commands[exported.name] = exported.default;
	});

export { commands as default };
