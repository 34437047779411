class Wrapper {
	constructor( error ) {
		this.error = error;
	}
}

// This utility function isolates `try ... catch` statements
// into an inner function that always returns a value and does
// not contain complex logic on its catch path.
// This prevents de-optimizations.
function tryCatch( fn, context, args ) {
	let result;

	try {
		result = fn.apply( context, args );
	} catch ( error ) {
		result = new Wrapper( error );
	}
	return result;
}

export function withErrorCallback( fn ) {
	return function( err, ...args ) {
		const result = tryCatch( fn, this, args );
		if ( result instanceof Wrapper ) err( result.error );
	};
}
