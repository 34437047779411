/* global KONNEKT_ENDPOINT process */
const endpoint = KONNEKT_ENDPOINT;

export const name = "open";
export default function open( options = {}) {
	const { type, id, returnUrl, ...other } = options;

	const extra = toQuery( other );

	const params = {
		"ApiKey"             : this.__key,
		"Language"           : this.__lang,
		"Type"               : type,
		"Id"                 : id,
		"BackLinkUrl"        : returnUrl ?? open.__href,
		"PublicationChannel" : this.__publicationchannel,
		"ExtraParams"        : extra ? extra : undefined
	};

	const query = toQuery( params );

	open.__href = `${endpoint}?${query}`;
}

// Makes this property mockable for unit tests
Object.defineProperty( open, "__href", {
	configurable : ( process.env.NODE_ENV === "development" ),
	get() { return window.location.href },
	set( value ) { window.location.href = value }
});

function toQuery( obj ) {
	const query = Object
		.keys( obj )
		.reduce(( query, key ) => {
			const value = String( obj[ key ] ?? "" );
			if ( value === "" ) return query;

			const pair = `${encodeURIComponent( key )}=${encodeURIComponent( value )}`;

			return ( query !== "" ) ? `${query}&${pair}` : `${pair}`;
		}, "" );

	return query;
}
